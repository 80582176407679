<template>
	<div class="wrap_paging">
		<div class="com_paging">
			<a title="이전" class="prev" @click="prevPage" :class="{ disabled: currentPage === 1 }">
				<i class="ic_set ic_prev"></i>
			</a>
			<a
				:class="{ active: currentPage === item }"
				v-for="item in range(minPage, maxPage)"
				:key="item"
				@click="changePage(item)"
			>
				<span class="txt">{{ item }}</span>
			</a>
			<a title="다음" class="next" :class="{ disabled: currentPage === totalPages }" @click="nextPage"
				><i class="ic_set ic_next"></i
			></a>
		</div>
		<!--	<div class="com_slt w_80px">
			<select
				class="slt_normal"
				v-model="currentPerpage"
				@change="changePerPage(currentPerpage)"
			>
				<option
					v-for="(item, index) in optionPerPage"
					:key="index"
					:value="item"
				>
					{{ item }}
				</option>
			</select>
		</div>-->
	</div>
</template>
<script>
export default {
	name: 'BasePagination',
	props: {
		perPage: {
			type: Number,
			default: 20,
			description: '화면 당 보여줄 페이지 개수 ex) 20이면 한페이지당 20개 출력',
		},
		total: {
			type: Number,
			default: 0,
			description: '전체 Row 개수',
		},
	},
	computed: {
		totalPages() {
			if (this.pageCount > 0) return this.pageCount;
			if (this.total > 0) {
				return Math.ceil(this.total / this.perPage);
			}
			return 1;
		},
		pagesToDisplay() {
			if (this.totalPages > 0 && this.totalPages < this.defaultPageCount) {
				return this.totalPages;
			}
			return this.defaultPageCount;
		},
		minPage() {
			if (this.currentPage >= this.pagesToDisplay) {
				const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
				const newMaxPage = pagesToAdd + this.currentPage;
				if (newMaxPage > this.totalPages) {
					return this.totalPages - this.pagesToDisplay + 1;
				}
				return this.currentPage - pagesToAdd;
			} else {
				return 1;
			}
		},
		maxPage() {
			if (this.currentPage >= this.pagesToDisplay) {
				const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
				const newMaxPage = pagesToAdd + this.currentPage;
				if (newMaxPage < this.totalPages) {
					return newMaxPage;
				} else {
					return this.totalPages;
				}
			} else {
				return this.pagesToDisplay;
			}
		},
	},
	data() {
		return {
			defaultPageCount: 10, // 페이지 보여줄 개수 ex) < 1 2 3 4 5 6 7 8 9 10 >
			currentPage: 1,
			currentPerpage: 10,
			optionPerPage: [10, 20, 40, 80, 100],
		};
	},
	methods: {
		range(min, max) {
			let arr = [];
			for (let i = min; i <= max; i++) {
				arr.push(i);
			}
			return arr;
		},
		prevPage() {
			if (this.currentPage > 1) {
				this.currentPage -= 1;
				this.changePage(this.currentPage);
			}
		},
		nextPage() {
			if (this.currentPage < this.totalPages) {
				this.currentPage += 1;
				this.changePage(this.currentPage);
			}
		},
		changePerPage() {
			this.currentPage = 1;
			this.changePage(this.currentPage, this.currentPerpage);
		},
		changePage(currentPate, currentPerpage) {
			this.currentPage = currentPate;
			this.currentPerpage = currentPerpage || this.currentPerpage;
			const pageInfo = {
				currentPerpage: this.currentPerpage,
				currentPage: this.currentPage,
			};
			this.$emit('page', pageInfo);
		},
	},
};
</script>
