<template>
	<div class="container wrap_list">
		<h2>{{ pageTitle }}: {{ pageEntity.total }} 개</h2>
		<div class="com_list">
			<table class="com_tb_data tb_order_list" v-if="isSAAS()">
				<thead>
					<tr>
						<th class="date active">
							<span class="txt">결제일</span>
						</th>
						<th class="name">
							<span class="txt">결제자</span>
						</th>
						<th class="item flex">
							<span class="txt">상품정보</span>
						</th>
						<th class="charge">
							<span class="txt">금액(VAT포함)</span>
						</th>
						<th class="method">
							<span class="txt">결제방법</span>
						</th>
						<th class="state">
							<span class="txt">주문상태</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="order in tableData" :key="order.id">
						<td class="date" v-on:click="goDetailPage(order)">
							<span class="txt">
								<template v-if="order.paymentDate">{{ order.paymentDate | convertYYMMDD }}</template>
								<template v-else>-</template>
							</span>
							<span class="com_tag" v-if="order.payType == 'TRANSPORT'">
								<span class="txt">구매 신청일</span>
							</span>
						</td>
						<td class="name" v-on:click="goDetailPage(order)">
							<span class="txt"
								><template v-if="order.paymenter">{{ order.paymenter }}</template> <template v-else>-</template></span
							>
						</td>
						<td class="item flex" v-on:click="goDetailPage(order)">
							<span class="txt">{{ order.itemInfo }}</span>
						</td>
						<td class="charge" v-on:click="goDetailPage(order)">
							<span class="txt">{{ order.amount | currency }}원</span>
						</td>
						<td class="method" v-on:click="goDetailPage(order)">
							<span class="txt">{{ order.payMethod }}</span>
						</td>
						<td class="state">
							<template v-if="order.orderStatus == 'WAIT'">
								<span class="label_state active">결제대기</span>
								<span class="txt"
									><a
										v-on:click="
											orderCancleConfirm.orderId = order.id;
											orderCancleConfirm.flag = true;
										"
										>주문취소</a
									></span
								>
							</template>
							<span v-if="order.orderStatus == 'COMPLETE'" class="label_state inactive">결제완료</span>
							<span v-if="order.orderStatus == 'DROP'" class="label_state abnormal">주문취소</span>
							<span v-if="order.orderStatus == 'CANCEL'" class="label_state abnormal">전체환불</span>
							<span v-if="order.orderStatus == 'PART_CANCEL'" class="label_state abnormal">부분환불</span>
						</td>
					</tr>
				</tbody>
			</table>

			<table class="com_tb_data tb_order_list" v-else>
				<thead>
					<tr>
						<th class="orderType">
							<span class="txt">주문 종류</span>
						</th>
						<th class="date">
							<span class="txt">계약일</span>
						</th>
						<th class="date">
							<span class="txt">적용 시작일</span>
						</th>
						<th class="name">
							<span class="txt">계약명</span>
						</th>
						<th class="item flex">
							<span class="txt">상품정보</span>
						</th>
						<th class="charge">
							<span class="txt">총 계약금액(VAT포함)</span>
						</th>
						<th class="state">
							<span class="txt">주문상태</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="order in tableData" :key="order.id">
						<td class="orderType" v-on:click="goDetailPage(order)">
							<span class="txt" v-if="order.orderType == 'FRESH'">신규</span>
							<span class="txt" v-if="order.orderType == 'ADD'">추가</span>
							<span class="txt" v-if="order.orderType == 'EXTENSION'">연장</span>
							<span class="txt" v-if="order.orderType == 'ADD_EXTENSION'">연장추가</span>
							<span class="txt" v-if="order.orderType == 'CHANGE'">변경</span>
						</td>
						<td class="date" v-on:click="goDetailPage(order)">
							<span class="txt">
								<template v-if="order.contractDate">{{ order.contractDate | convertYYMMDD }}</template>
								<template v-else>-</template>
							</span>
						</td>
						<td class="date" v-on:click="goDetailPage(order)">
							<span class="txt">
								<template v-if="order.applyDate">{{ order.applyDate | convertYYMMDD }}</template>
								<template v-else>-</template>
							</span>
						</td>
						<td class="name" v-on:click="goDetailPage(order)">
							<span class="txt">
								<template v-if="order.contractName">{{ order.contractName }}</template>
								<template v-else>-</template>
							</span>
						</td>
						<td class="item flex" v-on:click="goDetailPage(order)">
							<span class="txt">{{ order.itemInfo }}</span>
						</td>
						<td class="charge" v-on:click="goDetailPage(order)">
							<span class="txt">{{ order.amount | currency }}원</span>
						</td>
						<td class="state">
							<template v-if="order.orderStatus == 'WAIT'">
								<span class="label_state active">결제대기</span>
								<span class="txt"
									><a
										v-on:click="
											orderCancleConfirm.orderId = order.id;
											orderCancleConfirm.flag = true;
										"
										>주문취소</a
									></span
								>
							</template>
							<span v-if="order.orderStatus == 'COMPLETE'" class="label_state inactive">결제완료</span>
							<span v-if="order.orderStatus == 'DROP'" class="label_state abnormal">주문취소</span>
							<span v-if="order.orderStatus == 'CANCEL'" class="label_state abnormal">전체환불</span>
							<span v-if="order.orderStatus == 'PART_CANCEL'" class="label_state abnormal">부분환불</span>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="wrap_function">
				<base-pagination
					v-bind:total="pageEntity.total"
					v-bind:perPage="pageEntity.perPage"
					v-on:page="onPage"
				></base-pagination>
			</div>
		</div>

		<div
			v-if="orderCancleConfirm.flag"
			class="com_layer_confirm layer_order_cancel"
			style="position: absolute; left: 30%; top: 30%; z-index: 9998px"
		>
			<div class="lay_header">
				<h1>선택한 주문을 취소하시겠습니까?</h1>
				<a class="btn_close" title="닫기" v-on:click="orderCancleConfirm.flag = false"
					><span class="ic_set ic_close"></span
				></a>
			</div>
			<!--content-->
			<div class="lay_body">
				<p class="txt"></p>
			</div>
			<!--//content end-->
			<div class="lay_bottom">
				<div class="wrap_function">
					<button class="major" v-on:click="orderCancel(orderCancleConfirm.orderId)">주문 취소</button>
					<button class="minor" v-on:click="orderCancleConfirm.flag = false">취소</button>
				</div>
			</div>
		</div>
	</div>
	<!--//container-->
</template>

<script>
import BasePagination from '@/components/common/BasePagination.vue';
const payment = {
	TRANSPORT: '무통장입금',
	CREDITCARD: '신용카드',
	REALTIME_TRANSPORT: '실시간 계좌이체',
	ADMIN_DIRECT_PAYMENT: '관리자 결제',
	MOBILE: '모바일 결제',
	AUTO_CREDITCARD: '자동 카드결제',
};
export default {
	components: {
		BasePagination,
	},
	data() {
		return {
			membershipInfo: {
				baseChannelInfoModel: {
					baseChannelModel: {
						serviceCategoryModel: { serviceType: 'SAAS' },
					},
				},
			},
			pageTitle: '주문/구매 목록',
			enteredMembershipId: -1,
			tableData: [],
			pageEntity: {
				total: 0, // 전체 Row수
				perPage: 10,
			},
			keyword: '',
			serviceType: [],
			status: [],
			orderCancleConfirm: {
				flag: false,
				orderId: '',
			},
		};
	},
	async created() {
		let data;
		try {
			data = await this.$store.dispatch('GETMEMBERSHIP');
			if (!data) {
				const token = sessionStorage.getItem('userToken');
				if (token) {
					const result = await this.$store.dispatch('USER_SSO', token);
					data = await this.$store.dispatch('GETMEMBERSHIP');
				}
			}
		} catch (e) {
			const token = sessionStorage.getItem('userToken');
			if (token) {
				const result = await this.$store.dispatch('USER_SSO', token);
				data = await this.$store.dispatch('GETMEMBERSHIP');
			}
		}
		this.membershipInfo = data;
		this.enteredMembershipId = data.id;
		if (!this.isSAAS()) {
			this.pageTitle = '주문/계약내역 목록';
		}
		this.baseList();
	},
	methods: {
		async orderCancel(orderId) {
			await this.$store.dispatch('CANCLEORDER', orderId);
			this.orderCancleConfirm.flag = false;

			this.$ms.dialog.alert('주문취소', '주문이 취소되었습니다.', () => {});
			this.baseList();
		},
		async baseList(pageInfo) {
			const param = {
				membershipId: this.enteredMembershipId,
				page: 0,
				size: 10,
			};

			if (pageInfo) {
				param.page = pageInfo.page;
				param.size = pageInfo.size;
			}
			try {
				const data = await this.$store.dispatch('GETORDERLIST', param);
				this.setTableData(data);
			} catch (error) {
				console.log(error);
			}
		},
		setTableData(resultData) {
			this.tableData = [];
			resultData.content.forEach((item) => {
				if (item.orderStatus !== 'DROP') {
					const tableData = {
						id: item.id,
						paymentDate:
							item.payType == 'TRANSPORT'
								? item.orderBaseInfoModel.orderDate
								: item.orderStatus == 'WAIT'
								? ''
								: item.orderBaseInfoModel.contractDate,
						paymenter:
							item.orderStatus == 'WAIT' ? '' : item.orderType == 'FRESH' ? item.membershipSimpleModel.adminName : '',
						itemInfo: item.summary,
						amount: item.amount,
						payMethod: payment[item.payType],
						payType: item.payType,
						orderStatus: item.orderStatus,
						orderType: item.orderType,
						contractDate: item.orderBaseInfoModel.contractDate,
						applyDate: item.orderBaseInfoModel.applyDate,
						contractName: item.contractName,
					};
					this.tableData.push(tableData);
				}
			});

			this.pageEntity.total = resultData.totalElements;
		},
		//TODO. goDetailPage, onPage, sorting, findAll을 컴포넌트 안으로 공통화
		goDetailPage(order) {
			/*if (
				(order.payType == 'CREDITCARD' ||
					order.payType == 'REALTIME_TRANSPORT') &&
				order.orderStatus == 'WAIT'
			) {
				this.$router.push(`/order/addExtension`);
			} else {*/
			this.$router.push(`/user/myStore/OrderDetail/${order.id}`);
			//}
		},
		onPage(obj) {
			const pageInfo = {
				page: obj.currentPage - 1,
				size: obj.currentPerpage,
			};
			this.baseList(pageInfo);
		},
		sorting(sortingType) {
			// TODO : 1. API 파라미터 명명규칙 작업
			//    	  2. 정렬 아이콘 (화살표 상,하 변경)
			this.findAll(sortingType);
		},
		findAll(sortingType) {},
		isSAAS() {
			return this.membershipInfo.baseChannelInfoModel.baseChannelModel.serviceCategoryModel.serviceType == 'SAAS';
		},
	},
};
</script>

<style></style>
